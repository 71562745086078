import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ds from '../theme'
import IcoStorage from '@material-ui/icons/Storage'
import IcoBrightness from '@material-ui/icons/Brightness4'
import IcoAcUnit from '@material-ui/icons/AcUnit'
import IcoEq from '@material-ui/icons/GraphicEq'
import IcoVpn from '@material-ui/icons/VpnLock'
import IcoNetwork from '@material-ui/icons/NetworkCheck'
import Check from '@material-ui/icons/CheckCircle'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Body2 from './../components/Body2'
import Div from './../components/Div'
import Body1 from './../components/Body1'
import { css } from '@emotion/core'

import H3 from '../components/H3'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import DcOrlando from '../images/data-centers/dc-orlando.jpg'
import DcOrlando2 from '../images/data-centers/dc-orlando-2.jpg'
import DcOrlando3 from '../images/data-centers/dc-orlando-3.jpg'
import DcOrlando4 from '../images/data-centers/dc-orlando-4.jpg'
import DcOrlando5 from '../images/data-centers/dc-orlando-5.jpg'
import DcOrlando6 from '../images/data-centers/dc-orlando-6.jpg'

import { withSolutions, SolutionContext } from '../components/SolutionProvider';
import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledDataCenters,
  styleEstructure,
  header,
  imgLarge,
  imagesRow
} from '../components/styles/DataCenters.styles'

const DcItem = ({
  img,
  title,
  children,
  icon: Icon,
  src,
  id
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#FFFFFF',
        borderRadius: 16
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: 68,
            height: 68,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderRadius: '50%',
            backgroundColor: '#f3f5f5',
            marginRight: ds.space(3)
          }}
        >
          {Icon && <Icon
            style={{
              color: 'rgba(0, 0, 0, 0.38)'
            }}
          />}
          {src && <img
            src={src}
          />}
        </div>
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            width: 170,
            lineHeight: 1.46,
            letterSpacing: 0.5
          }}
        >
          {title}
        </Body1>
      </Div>
      {children && (
        <Div
          style={{
            position: 'relative',
            opacity: isActive ? 1 : 0,
            maxHeight: isActive ? 700 : 0,
            transition: isActive ? 'max-height 2s, opacity 0.35s 0.35s' : 'max-height 0.8s 0.35s, z-index 0.7s 0.7s, opacity 0.7s',
            zIndex: isActive ? 1 : -1
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledDataCenters}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H3>
                <span>ESTRUTURA</span>
                Data Center Orlando
              </H3>
              <Paragraph style={{ marginBottom: 16 }}>Localizada em Orlando, Flórida, nossa instalação foi projetada tendo em mente a estabilidade, confiabilidade e o nível mais elevado de padrões. Com sistemas de redundância completa, conectividade de rede, combate a incêndio, alta segurança e suporte 24h por dia, a Hostdime USA é consistentemente classificada como uma das principais instalações para se associar no Sudeste dos Estados Unidos.</Paragraph>
              <Paragraph>Em breve, nossa estrutura se tornará ainda mais completa e moderna. Com a inauguração das novas instalações de 88 mil metros quadrados, a HostDime será o único data center Tier 4 do centro da Flórida.</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            mt={[40]}
          >
            <Column
              mb={[8]}
            >
              <Div
                maxWidth={['100%', 930]}
              >
                <img css={imgLarge} src={DcOrlando} />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'row', 'row']}
          >
            <Column
              mb={[8]}
              px={[8, 8, 0, 0]}
            >
              <Div
                maxWidth={['100%', '180px', '304px']}
              >
                <img src={DcOrlando2} css={imagesRow} />
              </Div>
            </Column>
            <Column
              mb={[8]}
              px={[8, 8, 0, 0]}
              mx={[0, 0, 8, 8]}
            >
              <Div
                maxWidth={['100%', '180px', '304px']}
              >
                <img src={DcOrlando3} css={imagesRow} />
              </Div>
            </Column>
            <Column
              mb={[8]}
              px={[8, 8, 0, 0]}
            >
              <Div
                maxWidth={['100%', '180px', '304px']}
              >
                <img src={DcOrlando4} css={imagesRow} />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Column
              mb={[8, 8, 8, 0]}
              px={[8, 8, 0, 0]}
              mr={[0, 0, 4, 4]}
            >
              <Div
                maxWidth={['100%', '100%', '100%', '100%']}
              >
                <img src={DcOrlando5} />
              </Div>
            </Column>
            <Column
              mb={[8, 8, 8, 0]}
              px={[8, 8, 0, 0]}
              ml={[0, 0, 4, 4]}
            >
              <Div
                maxWidth={['100%', '100%', '100%', '100%']}
              >
                <img src={DcOrlando6} />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={styleEstructure}>
        <Container>
          <Row
            justifyContent={'center'}
            pt={40}
            pb={30}
          >
            <Column>
              <H5>Saiba mais sobre<br /> nossa estrutura</H5>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pb={50}
          >
            <Column
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <DcItem
                  icon={IcoStorage}
                  title='Espaço e Infraestrutura'
                  id='solution01'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      25.000 metros quadrados
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Edifício projetado com alvenaria estrutural e concreto
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Categoria TIER-4, no Uptime Institute, com disponibilidade 99.99% resistente a tempestades
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Sala de conferência e lounge de entrada para clientes e visitantes
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoAcUnit}
                  title='Poder de Alta Disponibilidade'
                  id='solution02'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      1 x 500 KW gerador a diesel - Caterpillar
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      1 x 1250 KW gerador a diesel - Katolight
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      1 x 500 KVA Powerware 9315 UPS
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      1 x 480 KVA UPS Powerware 9390 em uma configuração N + 1 paralela
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      5 x cordas de bateria (fornecendo 15 minutos de tempo de execução a plena carga)
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      2.700 galões de combustível com capacidade para geradores
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Análises e rotinas de manutenção dos sistemas
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoBrightness}
                  title='Refrigeração de Alta Eficiência'
                  id='solution03'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Constante 72 F/ 45% de controle de precisão de umidade
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Zonas separadas de refrigeração
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      359 toneladas com capacidade de esfriamento total compostas por:<br /><br />
                      6 x 30 Ton Liebert Unidades CRAC<br /><br />
                      3 x 20 Ton Liebert Unidades CRAC<br /><br />
                      2 x 15 Ton Liebert Unidades CRAC<br /><br />
                      Unidades de ar - 2 x 25 toneladas<br /><br />
                      Unidade de ar - 1 x 15 toneladas<br /><br />
                      Unidades de ar - 2 x 12 toneladas
                    </Div>
                  </Div>
                </DcItem>
              </Div>
            </Column>
            <Column
              mt={[8, 8, 0, 0]}
            >
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <DcItem
                  icon={IcoEq}
                  title='Monitoramento Efetivo'
                  id='solution04'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Controles e monitoramento de missão crítica de última geração
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Patente HD-World DCIM que fornece o monitoramento em tempo real e relatórios de entrega de energia, sistemas de refrigeração e condições ambientais de piso de dados para maximizar a otimização, tempo de atividade e a eficiência operacional
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Gerenciamento e planejamento eficazes da capacidade
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Portal do cliente Core com dados instantâneos de utilização de energia 24x7, gerenciamento de chamados, relatórios de utilização de largura de banda e gerenciamento de crachás de acesso a contas de clientes
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoVpn}
                  title='Segurança Impenetrável'
                  id='solution05'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Ambiente gerenciado 24x7x365, capaz de evitar falhas de segurança, fraudes, ameaças e invasões
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Controle de acesso restrito e segurança física eletrônica
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Entrada com dois conjuntos de portas interligadas (Interlocking Man-trap), com acesso exclusivo e leitores biométricos fornecidos pela Segurança ADT
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Mais de 50 câmeras oferecendo vigilância de monitoramento 24 horas em toda a estrutura do data center
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoNetwork}
                  title='Conectividade e Largura de banda'
                  id='solution06'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Conexão direta ao PTT-SP
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Carrier Neutral
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Telefônica
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Global Crossing
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Algar Telecom
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Oi
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone AES
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Conectado ao NAP of the Americas
                    </Div>
                  </Div>
                </DcItem>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Data Center Orlando' />
      <PageTemplate
        title='Data Center Orlando'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
